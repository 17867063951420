@tailwind base;
@tailwind components;
@tailwind utilities;
/* 
html {
  position: fixed;
} */

html,
body,
#root {
  height: 100%;
}
body {
  margin: 0;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  position: fixed;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
